<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">List Target Pendukung</h3>
        </div>
        <div class="panel-body p-5">
          <vue-good-table
            :search-options="{
              enabled: true,
              placeholder: 'Pencarian',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-white mr-2"
                >
                  <i v-if="isLoading" class="fa fa-sync fa-spin"></i>
                  <i v-else class="fa fa-sync"></i>
                </button>
                <button
                  type="button"
                  @click="showModal"
                  class="btn btn-primary mr-2"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tambah Data</span>
                </button>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <button
                    class="btn btn-warning btn-circle btn-icon m-r-5"
                    v-on:click="
                      editTarget(
                        props.row.id_target,
                        props.row.kab,
                        props.row.kec,
                        props.row.kel,
                        props.row.target
                      )
                    "
                  >
                    <i class="fa fa-pencil-alt"></i>
                  </button>
                  <button
                    class="btn btn-danger btn-circle btn-icon"
                    v-on:click="confirmDelete(props.row.id_target)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Target Pendukung</h5>
        <button
          type="button"
          @click="closeModal"
          class="btn btn-danger btn-sm btn-icon btn-circle"
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
      </template>
      <div>
        <div class="form-group">
          <label for="kabupaten">Kabupaten/Kota</label>
          <select
            v-model="form.kabupaten"
            name="kabupaten"
            id="kabupaten"
            v-on:change="fetchKecamatan()"
            class="form-control text-uppercase"
            :class="{
              'is-invalid': formValidate.kecamatan,
            }"
          >
            <option value="">Pilih</option>
            <option
              v-for="kabupaten in listKabupaten"
              :value="kabupaten.id_kab"
              :key="kabupaten.id_kab"
            >
              {{ kabupaten.nama_kabupaten }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="kecamatan">Kecamatan</label>
          <select
            v-model="form.kecamatan"
            name="kecamatan"
            id="kecamatan"
            v-on:change="fetchKelurahan()"
            class="form-control text-uppercase"
            :class="{
              'is-invalid': formValidate.kecamatan,
            }"
          >
            <option value="">Pilih</option>
            <option
              v-for="kecamatan in listKecamatan"
              :value="kecamatan.id_kec"
              :key="kecamatan.id_kec"
            >
              {{ kecamatan.nama_kecamatan }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="kelurahan">Desa/Kelurahan</label>
          <select
            v-model="form.kelurahan"
            name="kelurahan"
            id="kelurahan"
            class="form-control text-uppercase"
            :class="{
              'is-invalid': formValidate.kelurahan,
            }"
          >
            <option value="">Pilih</option>
            <option
              v-for="kelurahan in listKelurahan"
              :value="kelurahan.id_kel"
              :key="kelurahan.id_kel"
            >
              {{ kelurahan.nama_kelurahan }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="target">Target</label>
          <input
            v-model="form.target"
            type="number"
            name="target"
            id="target"
            class="form-control"
            :class="{
              'is-invalid': formValidate.target,
            }"
            placeholder="Target"
          />
        </div>
      </div>
      <template #modal-footer>
        <button
          @click="postData"
          class="btn btn-block btn-info"
          variant="primary"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          Simpan
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "Kabupaten",
          field: "nama_kab",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Kecamatan",
          field: "nama_kec",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Desa",
          field: "nama_kel",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Target",
          field: "target",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      form: {
        id_target: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        target: "",
      },
      modalVisible: false,
      conditionMet: false,
      formValidate: [],
      listKabupaten: [],
      listKecamatan: [],
      listKelurahan: [],
      link: "/v1/target/create/",
    };
  },
  mounted() {
    this.fetchData();
    this.fetchKabupaten();
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/target/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.isLoading = true;
      const formData = new FormData();

      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      if (this.form.id_target) {
        this.link = "/v1/target/update/";
      }

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.isLoading = false;
            this.formValidate = response.data.message;
          } else {
            this.isLoading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            this.modalVisible = false;
            this.conditionMet = true;
            setTimeout(() => {
              Swal.close();
              this.resetForm();
              this.fetchData();
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/target/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    editTarget(id, kab, kec, kel, target) {
      this.form.id_target = id;
      this.form.kabupaten = kab;
      this.form.kecamatan = kec;
      this.form.kelurahan = kel;
      this.form.target = target;
      this.modalVisible = true;
      this.conditionMet = false;
      this.fetchKecamatan();
      this.fetchKelurahan();
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },

    resetForm() {
      this.form.kode_dapil = "";
      this.form.nama_dapil = "";
      this.formValidate = [];
    },

    fetchKabupaten() {
      axios
        .get("/v1/daerah/kabupaten/73", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKabupaten = response.data.data;
          this.fetchData();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchKecamatan() {
      if (this.form.kabupaten) {
        this.listKecamatan = [];
        axios
          .get("/v1/daerah/kecamatan/" + this.form.kabupaten, {
            headers: {
              token: this.userToken,
            },
          })
          .then((response) => {
            this.listKecamatan = response.data.data;
            this.fetchData();
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      } else {
        this.listKecamatan = [];
      }
    },

    fetchKelurahan() {
      if (this.form.kecamatan) {
        this.listKelurahan = [];
        axios
          .get("/v1/daerah/kelurahan/" + this.form.kecamatan, {
            headers: {
              token: this.userToken,
            },
          })
          .then((response) => {
            this.listKelurahan = response.data.data;
            this.fetchData();
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      } else {
        this.listKelurahan = [];
      }
    },
  },
};
</script>